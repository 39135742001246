import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SearchIcon, XIcon } from 'lucide-react';

import { useLocalDBContext } from '@/providers/LocalDBProvider';
import { PageMetadata } from '@/types/page.types';

import { CustomModal } from '@/components/ui/modal';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { useMediaQuery } from 'usehooks-ts';

const SearchButton = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div
        className="w-full flex items-center justify-between px-4 bg-primary cursor-pointer"
        onClick={showModal}
      >
        <div className="flex items-center">
          <span className="text-md font-semibold">Search</span>
          <Button variant="ghost" size="icon" className="p-0 m-0 hover:bg-transparent">
            <SearchIcon className="h-4 w-4" />
          </Button>
        </div>
        <kbd className="pointer-events-none ml-auto inline-flex h-5 select-none items-center gap-1 rounded border bg-secondary px-1.5 font-mono text-[.625rem] font-medium text-secondary-foreground opacity-100">
          <span className="text-xs">CTRL</span>K
        </kbd>
      </div>
      <SearchModal isOpen={isModalOpen} onClose={handleCancel} />
    </>
  );
};

export default SearchButton;

export const SearchModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const { searchPages, pagesMetadata } = useLocalDBContext();
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState<PageMetadata[]>([]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchQuery) {
        searchPages(searchQuery)
          .then(results => {
            setSearchResults(results);
          })
          .catch(() => {
            setSearchResults([]);
          });
      } else {
        setSearchResults(pagesMetadata);
      }
    }, 100);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [searchQuery, searchPages, pagesMetadata]);

  const openNotes = (pageId: string) => {
    navigate(`/page/${String(pageId)}`);
    onClose();
    setSearchQuery('');
  };
  return (
    <CustomModal isOpen={isOpen} onClose={onClose}>
      <SearchBox setSearchQuery={setSearchQuery} searchQuery={searchQuery} />
      <div className="px-3 py-2 max-h-[300px] overflow-y-auto">
        <h4 className="font-semibold px-2 mb-2">Notes</h4>

        {!searchResults.length && (
          <div className="flex items-center p-2 rounded mb-4">
            <span className="mr-2">😟</span>
            No notes found...
          </div>
        )}

        {searchResults.map((doc, index) => (
          <div
            key={index}
            className="flex items-center p-2 cursor-pointer hover:bg-muted hover:text-foreground rounded"
            onClick={() => {
              openNotes(doc.id);
            }}
          >
            <span className="mr-2">📄</span>
            {doc.title}
          </div>
        ))}
      </div>
    </CustomModal>
  );
};

export const SearchBox = ({
  searchQuery,
  setSearchQuery,
}: {
  searchQuery: string;
  setSearchQuery: (value: string) => void;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const isMobile = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    if (isMobile) return;
    const timer = setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 200);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div className="flex items-center w-full border-b-2">
      <div className="flex w-full items-center px-3 p-2">
        <SearchIcon className="h-6 w-6" />
        <Input
          ref={inputRef}
          type="text"
          placeholder="Search notes by title..."
          className="bg-secondary border-none focus-visible:ring-0 focus-visible:ring-offset-0 focus-visible:border-0"
          value={searchQuery}
          onChange={e => {
            setSearchQuery(e.target.value);
          }}
        />
      </div>
      {searchQuery && (
        <Button
          variant="ghost"
          size="icon"
          className="p-0 m-0 opacity-40 hover:bg-transparent"
          onClick={() => {
            setSearchQuery('');
          }}
        >
          <XIcon className="h-4 w-4" />
        </Button>
      )}
    </div>
  );
};
