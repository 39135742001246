import { useEffect } from 'react';
import { useModal } from '@/providers/ModalProvider';
import { useSyncContext } from '@/providers/SyncProvider';
import { useLocalDBContext } from '@/providers/LocalDBProvider';
import { useNavigate } from 'react-router-dom';

export const useKeyboardCommands = () => {
  const navigate = useNavigate();
  const { toggleModal } = useModal();
  const { syncLoading, handleSync } = useSyncContext();
  const { addPage } = useLocalDBContext();

  useEffect(() => {
    const down = async (e: KeyboardEvent) => {
      if (e.key.toLocaleLowerCase() === 's' && (e.metaKey || e.ctrlKey) && !syncLoading) {
        e.preventDefault();
        void handleSync();
      }
      if (e.key.toLocaleLowerCase() === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        toggleModal('search');
      }
      if (e.key.toLocaleLowerCase() === 'n' && e.altKey) {
        e.preventDefault();
        const pageId = await addPage();
        navigate(`/page/${String(pageId)}`);
      }
    };

    document.addEventListener('keydown', down);
    return () => {
      document.removeEventListener('keydown', down);
    };
  }, [addPage, handleSync, navigate, syncLoading, toggleModal]);
};
