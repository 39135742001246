import { memo, useCallback, useEffect } from 'react';
import { debounce } from 'lodash-es';
import { useLocalDBContext } from '@/providers/LocalDBProvider';
import Editor from '@/components/Editor';
import PageTitle from '@/components/PageTitle';
import { PageMetadata } from '@/types/page.types';
import { useSaveHook } from '@/hooks/useSaveHook';

const HomePage = () => {
  const {
    currentPage,
    pagesMetadata,
    setCurrentPageId,
    updateCurrentPageContent,
    updatePageTitle,
  } = useLocalDBContext();

  useSaveHook();

  const debouncedSetCurrentPageId = useCallback(
    debounce((metadata: PageMetadata[]) => {
      if (metadata.length) {
        void setCurrentPageId(metadata[0].id);
      } else {
        void setCurrentPageId();
      }
    }, 100),
    [setCurrentPageId]
  );

  useEffect(() => {
    debouncedSetCurrentPageId(pagesMetadata);
    return () => {
      debouncedSetCurrentPageId.cancel();
    };
  }, [pagesMetadata.length]);

  if (!currentPage) {
    return null;
  }

  return (
    <div className="overflow-y-auto overflow-x-hidden">
      <div className="mx-auto md:max-w-3xl lg:max-w-4xl">
        <PageTitle
          title={currentPage.title || 'Untitled'}
          onChange={newTitle => {
            updatePageTitle(currentPage.id, newTitle);
          }}
        />
        <Editor
          key={currentPage.id}
          initialContent={currentPage.content}
          onChange={updateCurrentPageContent}
        />
      </div>
    </div>
  );
};

export default memo(HomePage);
