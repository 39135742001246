import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { SyncEngine } from '@/services/database/SyncEngine';
import { useLocalDBContext } from './LocalDBProvider';
import { AuthService } from '@/services/auth/AuthService';
import { User } from 'firebase/auth';
import localDbService from '@/services/database/LocalDBService';
import { debounce } from 'lodash-es';

interface SyncState {
  user: User | null;
  authLoading: boolean;
  syncLoading: boolean;
  error: Error | null;
  lastSyncedAt: number | null;
  syncStatus: 'idle' | 'syncing' | 'error' | 'success';
}

interface SyncContextType extends SyncState {
  signIn: () => Promise<void>;
  signOut: () => Promise<void>;
  handleSync: () => Promise<void>;
}

const SyncContext = createContext<SyncContextType | undefined>(undefined);

interface SyncProviderProps {
  children: ReactNode;
  syncInterval?: number;
}

export const SyncProvider: React.FC<SyncProviderProps> = ({ children, syncInterval = 500 }) => {
  // State management using reducer for atomic updates
  const [state, setState] = useState<SyncState>({
    user: null,
    authLoading: true, // Start as true since we're checking auth
    syncLoading: false,
    error: null,
    lastSyncedAt: null,
    syncStatus: 'idle',
  });

  const { loadPages, setIsDirty, refreshCurrentPage, refreshTags } = useLocalDBContext();

  // Services initialization
  const authService = useMemo(() => new AuthService(), []);
  const syncEngine = useMemo(() => new SyncEngine(), []);

  // Abort controller for cleanup
  const abortControllerRef = useRef<AbortController>();

  // Sync queue management
  const syncQueueRef = useRef<Promise<void>>(Promise.resolve());
  const isSyncingRef = useRef(false);

  // Sync core with better error handling and retry logic
  const syncCore = useCallback(async () => {
    if (isSyncingRef.current) return;

    try {
      isSyncingRef.current = true;
      setState(prev => ({ ...prev, syncLoading: true, syncStatus: 'syncing' }));

      // Create new abort controller for this sync
      abortControllerRef.current = new AbortController();

      // Queue this sync operation
      syncQueueRef.current = syncQueueRef.current.then(async () => {
        const lastSynced = await syncEngine.performSync();

        // Check if we've been aborted
        if (abortControllerRef.current?.signal.aborted) return;

        setIsDirty(false);
        await loadPages();
        await refreshCurrentPage();
        await refreshTags();

        setState(prev => ({
          ...prev,
          syncLoading: false,
          error: null,
          lastSyncedAt: lastSynced,
          syncStatus: 'success',
        }));
      });

      await syncQueueRef.current;
    } catch (err) {
      // Only update error state if we haven't been aborted
      if (!abortControllerRef.current?.signal.aborted) {
        console.error('Sync failed:', err);
        setState(prev => ({
          ...prev,
          syncLoading: false,
          error: err instanceof Error ? err : new Error('Sync failed'),
          syncStatus: 'error',
        }));
      }
    } finally {
      isSyncingRef.current = false;
    }
  }, [syncEngine, loadPages]);

  // Debounced sync with cleanup
  const debouncedSync = useMemo(
    () =>
      debounce(syncCore, syncInterval, {
        leading: false,
        trailing: true,
      }),
    [syncCore, syncInterval]
  );

  // Cleanup function
  useEffect(() => {
    return () => {
      debouncedSync.cancel();
      abortControllerRef.current?.abort();
    };
  }, [debouncedSync]);

  // Auth state management
  useEffect(() => {
    const unsubscribe = authService.onAuthStateChanged(async newUser => {
      setState(prev => ({
        ...prev,
        user: newUser,
        authLoading: false,
      }));

      if (newUser) {
        await handleSync();
      } else {
        const lastSyncedAt = await localDbService.getLastSyncTime();
        setState(prev => ({ ...prev, lastSyncedAt }));
      }
    });

    return () => {
      unsubscribe();
    };
  }, [authService]);

  // Public methods
  const handleSync = useCallback(async () => {
    if (state.syncLoading) return;
    await debouncedSync();
  }, [state.syncLoading, debouncedSync]);

  const signIn = useCallback(async () => {
    try {
      setState(prev => ({ ...prev, authLoading: true, error: null }));
      await authService.signIn();
    } catch (err) {
      setState(prev => ({
        ...prev,
        error: err instanceof Error ? err : new Error('Sign in failed'),
        authLoading: false,
      }));
      throw err;
    }
  }, [authService]);

  const signOut = useCallback(async () => {
    try {
      setState(prev => ({ ...prev, error: null }));
      await authService.signOut();
      setState(prev => ({ ...prev, lastSyncedAt: null }));
    } catch (err) {
      setState(prev => ({
        ...prev,
        error: err instanceof Error ? err : new Error('Sign out failed'),
      }));
      throw err;
    }
  }, [authService]);

  const value = useMemo(
    () => ({
      ...state,
      signIn,
      signOut,
      handleSync,
    }),
    [state, signIn, signOut, handleSync]
  );

  return <SyncContext.Provider value={value}>{children}</SyncContext.Provider>;
};

export const useSyncContext = () => {
  const context = useContext(SyncContext);
  if (context === undefined) {
    throw new Error('useSyncContext must be used within a SyncProvider');
  }
  return context;
};
