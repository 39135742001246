import { ReactNode, useEffect, useRef, useState } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';

import HomePage from '@/pages/HomePage';
import DocumentPage from '@/pages/DocumentPage';
import SharedPage from '@/pages/SharedPage';

import Sidebar from '@/components/Sidebar';
import Menu from '@/components/Menu';

import { ProviderWrapper } from './providers';
import PrivacyPolicy from './pages/Privacy';
import { ArrowUp } from 'lucide-react';
import { Button } from './components/ui/button';

export default function App() {
  return (
    <ProviderWrapper>
      <Routes>
        {/* Shared route without sidebar/menu */}
        <Route path="/shared/:pageId" element={<SharedPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />

        {/* Routes with sidebar/menu layout */}
        <Route
          path="/"
          element={
            <MainLayout>
              <HomePage />
            </MainLayout>
          }
        />
        <Route
          path="/page/:pageId"
          element={
            <MainLayout>
              <DocumentPage />
            </MainLayout>
          }
        />
      </Routes>
    </ProviderWrapper>
  );
}

const MainLayout = ({ children }: { children: ReactNode }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const { pageId } = useParams<{ pageId: string }>();

  const scrollToTop = () => {
    containerRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      requestAnimationFrame(() => {
        setTimeout(() => {
          scrollToTop();
        }, 100);
      });
    }
  }, [pageId]);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const handleScroll = () => {
      setShowScrollButton(container.scrollTop > 400);
    };

    container.addEventListener('scroll', handleScroll);
    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="flex h-screen">
      <Sidebar />
      <main ref={containerRef} className="flex-1 overflow-y-auto relative overflow-x-hidden">
        <Menu />
        {children}
        {showScrollButton && (
          <Button
            onClick={scrollToTop}
            className="fixed bottom-4 right-4 h-8 w-8 p-0 z-1000"
            variant="secondary"
            size="icon"
          >
            <ArrowUp className="h-4 w-4" />
          </Button>
        )}
      </main>
    </div>
  );
};
