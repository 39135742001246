import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { debounce } from 'lodash-es';
import { DotIcon } from 'lucide-react';
import Editor from '@/components/Editor';
import { useLocalDBContext } from '@/providers/LocalDBProvider';
import PageTitle from '@/components/PageTitle';
import { useSaveHook } from '@/hooks/useSaveHook';
import { useSyncContext } from '@/providers/SyncProvider';

const DocumentPage = () => {
  const [showLoader, setShowLoader] = useState<boolean>(false);

  const { pageId } = useParams<{ pageId: string }>();
  const { loading, currentPage, setCurrentPageId, updateCurrentPageContent, updatePageTitle } =
    useLocalDBContext();
  const { lastSyncedAt } = useSyncContext();

  useSaveHook();

  useEffect(() => {
    if (loading) {
      const timer = setTimeout(() => {
        setShowLoader(true);
      }, 500);
      return () => {
        clearTimeout(timer);
        setShowLoader(false);
      };
    } else {
      setShowLoader(false);
    }
  }, [loading]);

  const debouncedSetCurrentPageId = useCallback(
    debounce((pageId: string) => {
      void setCurrentPageId(pageId);
    }, 100),
    [setCurrentPageId]
  );

  useEffect(() => {
    if (pageId) {
      debouncedSetCurrentPageId(pageId);
    }
  }, [debouncedSetCurrentPageId, pageId, setCurrentPageId]);

  if (showLoader) {
    return (
      <div className="w-full h-[75%] flex items-center justify-center">
        <DotIcon className="h-20 w-20 text-secondary-foreground animate-pulse opacity-50" />
      </div>
    );
  }

  if (!currentPage && !loading) {
    return (
      <div className="w-full flex items-center justify-center">
        <div className="flex-col space-y-4 text-center">
          <div className="text-3xl text-muted-foreground">¯/_(ツ)_/¯</div>
          <div className="text-xl text-muted-foreground">No notes found here</div>
        </div>
      </div>
    );
  }

  if (!currentPage) {
    return null;
  }

  return (
    <div>
      <div className="overflow-y-auto overflow-x-hidden">
        <div className="mx-auto md:max-w-3xl lg:max-w-4xl">
          {' '}
          <PageTitle
            key={currentPage.id}
            title={currentPage.title || 'Untitled'}
            onChange={newTitle => {
              updatePageTitle(currentPage.id, newTitle);
            }}
          />
          <Editor
            key={`${currentPage.id}_${String(lastSyncedAt)}`}
            initialContent={currentPage.content}
            onChange={updateCurrentPageContent}
          />
        </div>
      </div>
    </div>
  );
};

export default DocumentPage;
