import { useState, useEffect, useCallback, createContext, useContext } from 'react';
import { useLocalDBContext } from '@/providers/LocalDBProvider';
import {
  initializeGoogleSignIn,
  requestAccessToken,
  fetchUserInfo,
  searchForBackupFile,
  downloadBackupFile,
  uploadBackupFile,
  compareAndMergeNotes,
  User,
  TokenResponse,
} from '@/services/utils/googleDriveUtils';
import { Page } from '@/types/page.types';

interface GoogleDriveContextType {
  isConnected: boolean;
  user: User | null;
  isSyncing: boolean;
  lastSyncedAt: number | null;
  handleGoogleDriveConnect: () => void;
  syncWithGoogleDrive: () => Promise<void>;
}

const GoogleDriveContext = createContext<GoogleDriveContextType | undefined>(undefined);

export const GoogleDriveProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isConnected, setIsConnected] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const [isSyncing, setIsSyncing] = useState(false);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [lastSyncedAt, setLastSyncedAt] = useState<number | null>(null);

  const { getPages, syncRemoteWithLocal } = useLocalDBContext();

  const handleCredentialResponse = useCallback((response: { credential: string }) => {
    if (response.credential) {
      const decodedToken = JSON.parse(atob(response.credential.split('.')[1])) as User;
      setUser({
        sub: decodedToken.sub,
        name: decodedToken.name,
        email: decodedToken.email,
        picture: decodedToken.picture,
      });
    }
  }, []);

  const handleTokenResponse = useCallback((tokenResponse: TokenResponse) => {
    setAccessToken(tokenResponse.access_token);
    setIsConnected(true);
    localStorage.setItem('googleDriveAccessToken', tokenResponse.access_token);
    void fetchAndSetUserInfo(tokenResponse.access_token);
  }, []);

  const handleTokenExpiration = useCallback(() => {
    localStorage.removeItem('googleDriveAccessToken');
    setAccessToken(null);
    setIsConnected(false);
    setUser(null);
  }, []);

  const handleGoogleDriveConnect = useCallback(() => {
    requestAccessToken(handleTokenResponse);
  }, []);

  const fetchAndSetUserInfo = useCallback(async (token: string) => {
    try {
      const userInfo = await fetchUserInfo(token);
      setUser({
        id: userInfo.id,
        name: userInfo.name,
        email: userInfo.email,
        picture: userInfo.picture,
      });
    } catch (error) {
      console.error('Error fetching user info:', error);
      handleTokenExpiration();
    }
  }, []);

  const syncWithGoogleDrive = useCallback(async () => {
    if (!isConnected || !accessToken) {
      console.error('User not connected to Google Drive');
      handleTokenExpiration();
      return;
    }

    setIsSyncing(true);

    try {
      // Search for the backup file
      const searchResult = await searchForBackupFile(accessToken);

      let remoteNotes: Page[] = [];
      let remoteModifiedTime = null;
      if (searchResult.files && searchResult.files.length > 0) {
        const fileId = searchResult.files[0].id;
        remoteModifiedTime = new Date(searchResult.files[0].modifiedTime);
        const remoteNotesString = await downloadBackupFile(fileId, accessToken);
        remoteNotes = JSON.parse(remoteNotesString) as Page[];
      }

      // Get local notes
      const localNotes = await getPages();

      const lastRemoteSyncTime = remoteModifiedTime?.getTime() || null;

      // Compare and merge notes
      const { mergedNotesList, toAddInLocal, toUpdateInLocal, toDeleteFromLocal } =
        compareAndMergeNotes(localNotes, remoteNotes, lastRemoteSyncTime);

      // Update local notes
      if (toAddInLocal.length > 0 || toUpdateInLocal.length > 0) {
        await syncRemoteWithLocal(toAddInLocal, toUpdateInLocal, toDeleteFromLocal);
      }

      // Upload the latest notes list to Google Drive
      const fileContent = JSON.stringify(mergedNotesList);
      await uploadBackupFile(fileContent, searchResult.files?.[0]?.id || null, accessToken);

      // Update last synced time
      const currentTime = Date.now();
      localStorage.setItem('lastSyncedAt', String(currentTime));
      setLastSyncedAt(currentTime);

      console.log('Sync completed successfully');
    } catch (error) {
      console.error('Error syncing with Google Drive:', error);
      if (error instanceof Error && error.message === 'Invalid Credentials') {
        handleTokenExpiration();
      }
    } finally {
      setIsSyncing(false);
    }
  }, [
    isConnected,
    accessToken,
    getPages,
    setLastSyncedAt,
    syncRemoteWithLocal,
    handleTokenExpiration,
  ]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.defer = true;
    script.onload = () => {
      initializeGoogleSignIn(handleCredentialResponse, handleTokenResponse);
      // Auto-login if token exists
      const storedToken = localStorage.getItem('googleDriveAccessToken');
      if (storedToken) {
        setAccessToken(storedToken);
        setIsConnected(true);
        void fetchAndSetUserInfo(storedToken);
      }
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const value = {
    isConnected,
    user,
    isSyncing,
    lastSyncedAt,
    handleGoogleDriveConnect,
    syncWithGoogleDrive,
  };

  return <GoogleDriveContext.Provider value={value}>{children}</GoogleDriveContext.Provider>;
};

export const useGoogleDrive = (): GoogleDriveContextType => {
  const context = useContext(GoogleDriveContext);
  if (context === undefined) {
    throw new Error('useGoogleDrive must be used within a GoogleDriveProvider');
  }
  return context;
};
