import { useLocalDBContext } from '@/providers/LocalDBProvider';
import ListContainer from './ListContainer';
import FullList from './FullList';
import { useCallback, useEffect, useState } from 'react';

const LIST_ITEM_HEIGHT = 40;
const PRESET_LENGTH = 400;

const PageList = () => {
  const { pagesMetadata } = useLocalDBContext();
  const [maxDisplayed, setMaxDisplayed] = useState(8);

  const calculateMaxItems = useCallback(() => {
    const availableHeight = window.innerHeight - PRESET_LENGTH;
    const maxItems = Math.floor(availableHeight / LIST_ITEM_HEIGHT);
    // Ensure we always show at least 2 items and no more than necessary
    return Math.max(4, Math.min(maxItems, pagesMetadata.length));
  }, [pagesMetadata.length]);

  useEffect(() => {
    // Initial calculation
    setMaxDisplayed(calculateMaxItems());

    // Update on window resize
    const handleResize = () => {
      setMaxDisplayed(calculateMaxItems());
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [calculateMaxItems]);

  return (
    <div className="flex flex-col items-start space-y-1 relative">
      {pagesMetadata.length === 0 && (
        <span className="text-sm text-muted-foreground px-2 pt-3">Add more notes here</span>
      )}
      <ListContainer pagesMetadata={pagesMetadata} maxCount={maxDisplayed} />
      {pagesMetadata.length > maxDisplayed && <FullList pagesMetadata={pagesMetadata} />}
    </div>
  );
};

export default PageList;
