import { Tag, X } from 'lucide-react';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';

import { useLocalDBContext } from '@/providers/LocalDBProvider';
import TagSelector from './ui/TagSelector';

const TagInput = ({ editable }: { editable: boolean }) => {
  const { currentPage, tags, assignTag, unassignTag, deleteTag, updateTag, toggleFilter } =
    useLocalDBContext();

  if (!currentPage) return null;

  const trigger = (
    <div className="mb-4 mx-0 md:mx-10 min-h-[48px] flex items-center">
      <div className="flex flex-wrap gap-2 items-center p-2">
        {(currentPage.tags || []).map(tag => (
          <div
            onClick={e => {
              e.stopPropagation();
              toggleFilter(tag);
            }}
            key={tag}
            className="flex items-center gap-2 px-3 py-1 bg-secondary rounded-full text-sm border-2 border-border cursor-pointer"
          >
            <span>{tag}</span>
            {editable ? (
              <button
                onClick={e => {
                  e.stopPropagation();
                  void unassignTag(tag);
                }}
                className="text-muted-foreground hover:text-foreground"
              >
                <X className="h-3 w-3" />
              </button>
            ) : null}
          </div>
        ))}
      </div>
      {editable ? (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <div className="flex items-center opacity-60 hover:opacity-100 transition-opacity cursor-pointer">
                <Tag className="h-4 w-4" />
              </div>
            </TooltipTrigger>
            <TooltipContent>
              <p>Tag Page</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      ) : null}
    </div>
  );

  return (
    <TagSelector
      trigger={trigger}
      tags={tags}
      selectedTags={currentPage.tags || []}
      onSelect={tag => ((currentPage.tags || []).includes(tag) ? unassignTag(tag) : assignTag(tag))}
      onDelete={deleteTag}
      onEdit={updateTag}
      allowNewTags
      emptyMessage="No tags yet"
    />
  );
};

export default TagInput;
