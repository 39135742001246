import { ArrowUpDown, Check } from 'lucide-react';
import { useMediaQuery } from 'usehooks-ts';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { useLocalDBContext } from '@/providers/LocalDBProvider';
import { OrderOption } from '@/types/page.types';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';

const ORDER_BY_OPTIONS: { title: string; value: OrderOption }[] = [
  {
    title: 'Last Updated',
    value: 'updatedAt',
  },
  {
    title: 'Date Created',
    value: 'createdAt',
  },
  {
    title: 'Manual',
    value: 'orderNumber',
  },
];

const OrderConfig = () => {
  const { orderedBy, updateOrderBy } = useLocalDBContext();
  const isMobile = useMediaQuery('(max-width: 768px)');

  return (
    <Popover>
      <PopoverTrigger className="w-full flex justify-center items-center">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <div className="h-8 w-8 flex justify-center items-center hover:bg-accent hover:text-accent-foreground rounded">
                <ArrowUpDown className="h-4 w-4" />
              </div>
            </TooltipTrigger>
            <TooltipContent>
              <p>Sort List</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </PopoverTrigger>
      <PopoverContent side={isMobile ? 'bottom' : 'right'} className="w-44 z-[1000] p-0 py-1">
        <div className="flex-col rounded space-y-0">
          {ORDER_BY_OPTIONS.map(opt => (
            <PopoverOption
              key={opt.value}
              title={opt.title}
              value={opt.value}
              isSelected={opt.value === orderedBy}
              onSelect={updateOrderBy}
            />
          ))}
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default OrderConfig;

const PopoverOption = ({
  title,
  isSelected,
  value,
  onSelect,
}: {
  title: string;
  value: OrderOption;
  isSelected: boolean;
  onSelect: (val: OrderOption) => void;
}) => {
  return (
    <div
      className="w-full pl-4 py-2 rounded cursor-pointer hover:bg-secondary flex justify-between items-center"
      onClick={() => {
        onSelect(value);
      }}
    >
      {title}
      {isSelected && <Check className="h-4 w-4 mr-4 group-hover:opacity-0 absolute right-2" />}
    </div>
  );
};
