import { useLocalDBContext } from '@/providers/LocalDBProvider';
import { ElementRef, useCallback, useRef, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import TagInput from './Tag';

type TitleProp = {
  title: string;
  allowEdit?: boolean;
  onChange: (newTitle: string) => void;
};

const PageTitle: React.FC<TitleProp> = ({ title, allowEdit = true, onChange }) => {
  const { editable } = useLocalDBContext();
  const inputRef = useRef<ElementRef<'textarea'>>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [value, setValue] = useState(title);

  const enableInput = useCallback(() => {
    if (!editable) {
      return;
    }
    setValue(title);
    setIsEditing(true);
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  }, [editable, title]);

  const disableInput = () => {
    setIsEditing(false);
  };

  const onKeyDown = useCallback((event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      disableInput();
    }
  }, []);

  const onInput = useCallback(
    (value: string) => {
      setValue(value);
      onChange(value || 'Untitled');
    },
    [onChange]
  );

  return (
    <div className="w-screen md:w-auto overflow-x-hidden">
      <TagInput editable={editable && allowEdit} />
      <div className="mx-4 md:mx-12  mb-0 md:mb-5 min-h-16">
        {isEditing && editable ? (
          <TextareaAutosize
            ref={inputRef}
            spellCheck="false"
            onBlur={disableInput}
            onKeyDown={onKeyDown}
            placeholder={value}
            value={value === 'Untitled' ? '' : value}
            onChange={e => {
              onInput(e.target.value);
            }}
            className="resize-none break-words bg-transparent text-3xl md:text-5xl font-bold outline-none w-full"
          />
        ) : (
          <div
            onClick={enableInput}
            className="break-words pb-[.7188rem] text-3xl md:text-5xl font-bold  outline-none"
          >
            {title}
          </div>
        )}
      </div>
    </div>
  );
};

export default PageTitle;
